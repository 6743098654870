import { render, staticRenderFns } from "./allocatePop.vue?vue&type=template&id=5030570c&"
import script from "./allocatePop.vue?vue&type=script&lang=js&"
export * from "./allocatePop.vue?vue&type=script&lang=js&"
import style0 from "./allocatePop.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports