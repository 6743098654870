import { render, staticRenderFns } from "./customerCredit.vue?vue&type=template&id=6fff408c&scoped=true&"
import script from "./customerCredit.vue?vue&type=script&lang=js&"
export * from "./customerCredit.vue?vue&type=script&lang=js&"
import style0 from "./customerCredit.vue?vue&type=style&index=0&id=6fff408c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fff408c",
  null
  
)

export default component.exports